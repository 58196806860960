import React, { useEffect, useState } from 'react';
import {useCompanyId, useCollection, PS_URLS} from '../../core';
import { Button, Container, Header, Icon, List, Segment, Message } from 'semantic-ui-react';
import { icp4GetData, icp4Onboarding, icp4SyncData, checkENodeConnectionStatus } from './icp4.client';
import { useHistory } from 'react-router-dom';
import { LoadingBlock } from '../../ui/Loading';
import { getAmProjectConfig } from '../../ps-models';
import { authStorage } from '../../auth';

export function ICP4Onboarding({projectId}: {projectId: string}) {
  const companyId = useCompanyId();
  const history = useHistory();
  const [linkUrl, setLinkUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAlreadyLinked, setIsAlreadyLinked] = useState(false);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const { isLinked } = await checkENodeConnectionStatus(companyId, projectId);
        setIsAlreadyLinked(isLinked);
        
        if (isLinked) {
          // Redirect to sync page if already connected
          history.push(PS_URLS(companyId).icp4Sync(projectId));
          return;
        }
        
        const url = await icp4Onboarding(companyId, projectId);
        setLinkUrl(url);
      } catch (err) {
        setError('Failed to check connection status');
        console.error('Error checking connection status:', err);
      } finally {
        setIsLoading(false);
      }
    };

    checkStatus();
  }, [companyId, history]);

  if (isLoading) {
    return (
      <Container>
        <Segment>
         <LoadingBlock message="Checking connection status..." />
        </Segment>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Segment>
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>{error}</p>
          </Message>
        </Segment>
      </Container>
    );
  }

  return (
    <Container>
      <Segment>
        <Header>Link Your Solar Devices</Header>
        <p>Click the button below to connect your solar devices to the platform.</p>
        {linkUrl && (
          <Button
            primary
            onClick={() => window.location.href = linkUrl}
          >
            Connect Solar Devices
          </Button>
        )}
      </Segment>
    </Container>
  );
}

export function ICP4Sync({projectId}: {projectId: string}) {
  const companyId = useCompanyId();
  const [syncing, setSyncing] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [solarInverters, setSolarInverters] = useState<any[]>([]);
  const collection = useCollection();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await icp4GetData(companyId, projectId);
        console.info('ICP4 data:', data);
        setUserData(data.userData);
        setSolarInverters(data.solarInverters);
      } catch (error) {
        console.error('Error fetching ICP4 data:', error);
      }
    }
    fetchData();
  }, [companyId]);

  const handleSync = async () => {
    setSyncing(true);
    try {
      console.info("Syncing ICP4 data for company's project:", companyId, projectId);
      const syncResult = await icp4SyncData(companyId, projectId, collection);
    } catch (error) {
      console.error('Error syncing ICP4 data:', error);
    }
    setSyncing(false);
  }

  return (
    <Segment>
      <h1>Sync your Solar Devices</h1>
      <p>
        Sync your Solar Devices to the Perlstreet Platform so that we can start monitoring your energy production for financial analysis and optimization.
      </p>
      <Button
        loading={syncing}
        onClick={handleSync} primary >
        Sync
        <Icon name='arrow right' />
      </Button>

      <h3>User Data</h3>
      {userData && (
        <List>
          <List.Item>
            <strong>User ID </strong>
            {userData.id}
          </List.Item>
          <List.Item>
            <strong>Linked Vendors </strong>
            {userData.linkedVendors.map((vendor: any, index: number) => (
              <List key={index}>
                <List.Item>
                  <strong>Vendor </strong>
                  {vendor.vendor}
                </List.Item>
                <List.Item>
                  <strong>Vendor Type </strong>
                  {vendor.vendorType}
                </List.Item>
              </List>
            ))}
          </List.Item>
        </List>
      )}

      <h3>Solar Inverters</h3>
      {solarInverters.map((inverter: any, index: number) => (
        <List key={index}>
          <List.Item>
            <strong>Inverter ID </strong>
            {inverter.id}
          </List.Item>
          <List.Item>
            <strong>Vendor </strong>
            {inverter.vendor}
          </List.Item>
          <List.Item>
            <strong>Last Seen </strong>
            {new Date(inverter.lastSeen).toLocaleString()}
          </List.Item>
          <List.Item>
            <strong>Is Reachable </strong>
            {inverter.isReachable ? 'Yes' : 'No'}
          </List.Item>
          <List.Item>
            <strong>Model </strong>
            {inverter.information.model}
          </List.Item>
          <List.Item>
            <strong>Installation Date </strong>
            {new Date(inverter.information.installationDate).toLocaleDateString()}
          </List.Item>
          <List.Item>
            <strong>Site Name </strong>
            {inverter.information.siteName}
          </List.Item>
          <List.Item>
            <strong>Production Rate </strong>
            {inverter.productionState.productionRate} kW
          </List.Item>
          <List.Item>
            <strong>Total Lifetime Production </strong>
            {inverter.productionState.totalLifetimeProduction} kWh
          </List.Item>
        </List>
      ))}
    </Segment>
  );
}